import { Link } from "react-router-dom";
import { FooterRoutes } from "../constant";
import { nanoid } from "nanoid";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/app/hooks";
import { setIsSticky } from "../redux/features/userAuth/userAuth";

type Props = {};

const FooterComponent = (props: Props) => {
  const FooterRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const handleScroll = () => {
      if (FooterRef?.current?.offsetTop) {
        if (window.pageYOffset >= (FooterRef?.current?.offsetTop - FooterRef?.current?.offsetHeight)) {
          dispatch(setIsSticky(true));
        } else {
          dispatch(setIsSticky(false));
        }
        if ((FooterRef?.current?.offsetTop - FooterRef?.current?.offsetHeight) <= 0) {
          dispatch(setIsSticky(false));
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [FooterRef]);

  return (
    <div className="justify-center items-center py-3  flex  flex-col bg-primary text-white">
      <div ref={FooterRef} className="container grid grid-cols-1  sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3 py-5 border-b border-b-utils-line">
        <div id={"target-div"} className="col-span-1 flex flex-col gap-5">
          <img className="w-[100px] " src="/logo 1.png" alt="/image.svg" />
          <div className="flex  gap-[10px]">
            <a href="https://www.facebook.com" target="_blank">
              {" "}
              <img src="/facebook.svg" alt="/image.svg" />
            </a>
            <a href="https://www.instagram.com" target="_blank">
              <img src="/instagram.svg" alt="/image.svg" />
            </a>
            <a href="https://www.youtube.com" target="_blank">
              <img src="/youtube.svg" alt="/image.svg" />
            </a>
            <a href="https://twitter.com" target="_blank">
              <img src="/twitter.svg" alt="/image.svg" />
            </a>
          </div>
          <h4 className="font-bold">Download App</h4>
          <div className="flex  justify-start gap-[30px]">
            <img src="/app store.svg" alt="/image.svg" />
            <img src="/play store.svg" alt="/image.svg" />
          </div>
        </div>
        {FooterRoutes.map((i) => (
          <div key={nanoid()} className="col-span-1 gap-8">
            <h4 className="font-bold mx-5 my-8">{i.officeType}</h4>
            <div className="flex flex-col gap-5">
              <h4 className="font-bold mx-5">{i.city}</h4>
              {i.contactInfo.map((i) => (
                <div key={nanoid()} className="flex items-start pt-1">
                  <img className="w-4 h-5 pr-1 pt-1" src={i.icon} alt="image.svg" />
                  <p className={i.type === "address" ? "min-h-[100px]" : ""}>{i.contact}</p>
                  <a className={i.type === "address" ? "min-h-[100px]" : ""} href={`mailto:${i.email}`}>
                    {i.email}
                  </a>
                  <a className={i.type === "address" ? "min-h-[100px]" : ""} href={`tel:${i.tel}`}>
                    {i.tel}
                  </a>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="container py-[12px]  text-sm flex flex-col md:flex-row md:gap-0 gap-3 justify-between">
        <p>Copyright @ 2023 VK JEWELS PVT.LTD. All right reserved.</p>
        <div className="flex gap-2 underline justify-center">
          <Link to={"/privacyPolicy"}>Privacy Policy </Link>
          <span className="w-[1px] bg-white"></span>
          <Link to={"/privacyPolicy"}>Terms of use</Link>
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;
