import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ButtonComponent from "../../components/ButtonComponent";
import MobileCodeDropDownComponent from "../../components/MobileCodeDropDownComponent";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { getCountries, verifyMobile, setMobileNumber, setOTPstatus, loginAPI, setCountryCode } from "../../redux/features/userAuth/userAuth";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import OTPInput from "react-otp-input";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toastComponent } from "../../components/ToastComponent";
import useCookies from "react-cookie/cjs/useCookies";
import backArrow from "../../assets/icons/up arrow.svg";

type Props = {};

const LoginPage = (props: Props) => {
  const [_cookie, setCookie] = useCookies(["authToken", "refreshToken", "type"]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const OTPsent = useAppSelector((state) => state.userAuth.OTPSent);
  const [OTPvalue, setOTPvalue] = useState("");

  const setFormValue = (e: any) => {
    setValue("country", e.id);
    setValue("country_code", e.phonecode);
    dispatch(setCountryCode({ countryCode: e.phonecode, id: e.id }));
  };

  const schema = yup.object().shape({
    mobile: yup.string().matches(/^\+?\d{10,14}$/, "Enter a Valid Mobile Number"),
  });
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
 
  useEffect(() => {
    Object.values(errors).forEach((element: any) => {
      toastComponent(element.message, "error");
    });
  }, [errors]);

  const countries = useAppSelector((state) => state.userAuth.countries);
  const country_code = useAppSelector((state) => state.userAuth.countryCode);

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  const mobileNumber = watch("mobile");

  const handleReset = () => {
    reset();
    setOTPvalue("");
    dispatch(setOTPstatus(false));
  };

  const [searchParams, setSearchParams] = useSearchParams();

  // Get the value of a specific query parameter
  const id = searchParams.get("id");

  const sentOTP = () => {
    dispatch(setMobileNumber(mobileNumber));
    dispatch(verifyMobile({ mobile_number: mobileNumber, catalog_id: id, country_code: country_code })).then((res) => {
      res.payload.success === true ? toastComponent(res.payload.message, "success") : toastComponent(res.payload.message, "error");
    });
  };
  const otpHandle = () => {
    if (OTPvalue.length <= 0) {
      toastComponent("Please enter OTP", "error");
    }
    dispatch(loginAPI({ mobile_number: mobileNumber, otp: OTPvalue, catalog_id: id, country_code: country_code })).then((res) => {
      if (res.payload.success === true && typeof res.payload.authToken === "string") {
        localStorage.setItem("name", res?.payload?.data?.full_name);
        localStorage.setItem("number", res?.payload?.data?.mobile_number);
        setCookie("authToken", res.payload.authToken, { path: "/" });
        setCookie("refreshToken", res.payload.refreshToken, { path: "/" });
        setCookie("type", 0, { path: "/" });
        toastComponent(res.payload.message, "success");
        navigate(`/catalogues/${id}`);
        dispatch(setOTPstatus(false));
      } else if (res.payload.is_register === false) {
        dispatch(setOTPstatus(false));
        navigate(`/register?id=${id}`);
      } else {
        dispatch(setOTPstatus(true));

        toastComponent("Incorrect OTP");
      }
    });
  };

  useEffect(() => {
    if (mobileNumber === "") {
      dispatch(setOTPstatus(false));
    }
  }, [mobileNumber]);

  useEffect(() => {
    document.title = "JEWEL | Login to Catalogue";
  }, []);

  return (
    <div>
      <div className="flex justify-center items-center flex-col ">
        <img className="mt-[50px] sm:mt-[100px] mb-[50px] w-[200px]" src="/logo 2.png" alt="asas" />
        <h3 className="text-primary pb-[30px] text-[20px] sm:text-[22px] font-bold ">Sign-in to view catalogue</h3>
        <form onSubmit={!OTPsent ? handleSubmit(sentOTP) : handleSubmit(otpHandle)} className="flex-center gap-[30px]  w-[90%] sm:w-[66%] md:w-[25%] flex-col">
          {OTPsent ? (
            <button type="button" className="w-full text-left flex flex-row items-center" onClick={handleReset}>
              <img src={backArrow} alt="" className="-rotate-90  h-3 w-3" /> Back
            </button>
          ) : null}
          <MobileCodeDropDownComponent
            isDisabled={OTPsent}
            register={register}
            inputRef={"mobile"}
            countries={countries}
            asterisk
            value={mobileNumber}
            placeHolder="Enter mobile number"
            label="Mobile Number"
            setFormValue={setFormValue}
          />
          {OTPsent ? (
            <div className="flex w-full flex-col">
              <label className="text-base font-bold text-primary" htmlFor="">
                Enter OTP
                <span className="text-red-500"> *</span>
              </label>
              <OTPInput
                containerStyle={"w-flex w-full justify-center"}
                inputStyle={"border-b-2 border-b-primary-light w-full flex px-4 mr-3 text-base outline-none"}
                value={OTPvalue}
                onChange={setOTPvalue}
                numInputs={6}
                inputType="tel"
                renderSeparator={<span></span>}
                renderInput={(props) => <input {...props} />}
              />
            </div>
          ) : null}
          <ButtonComponent buttonType={"submit"} CTA={!OTPsent ? "Get OTP" : "Log in"} variant={"secondary"} />
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
