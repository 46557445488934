import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux/app/hooks";
import { addItem, removeItem } from "../redux/features/distributoreCatalogue/distributoreCatalogue";
import { toastComponent } from "../components/ToastComponent";
import { distributorDesignFilter } from "../redux/features/distributoreCatalogue/distributoreCatalogue";
import { nanoid } from "nanoid";
import { useParams } from "react-router-dom";

type Props = {};

export const DistributoreCategoryFilter = ({}: any) => {
  const categoryIds = useAppSelector((state) => state.distributoreCatalogue.categoryIds);
  const dispatch = useAppDispatch();
  const categoryList = useAppSelector((state) => state.distributoreCatalogue.category);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(distributorDesignFilter(id));
    }
  }, [id]);

  function handleClick(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.checked) {
      if (categoryIds.length !== 1) {
        dispatch(removeItem({ id: e.target.value }));
      } else {
        toastComponent("Atleast 1 Category Need");
      }
    } else {
      dispatch(addItem({ id: e.target.value }));
    }
  }

  return (
    <ul className="cursor-pointer ">
      {categoryList?.map((i: any) => (
        <li key={nanoid()}>
          <label className="flex items-baseline">
            <input
              type="checkbox"
              name=""
              id=""
              onChange={(e) => handleClick(e)}
              value={i.category_id}
              checked={categoryIds.findIndex((element: any) => element === Number(i.category_id)) === -1 ? false : true}
            />
            <span className="mx-[15px]">{i.name}</span>
          </label>
        </li>
      ))}
    </ul>
  );
};
