type Props = {
  CTA: string;
  onClick: Function;
  icon?: string;
};

const TagComponent = ({ CTA, onClick, icon = "" }: Props) => {
  return (
    <div className={`border flex  justify-center items-center w-full text flex-nowrap whitespace-nowrap border-primary px-2 py-1 rounded-[7px] bg-white`}>
      {icon !== "" && (
        <div className="flex w-5 h-5">
          <img className="w-5 h-5" src={icon} alt="" />
        </div>
      )}
      <div onClick={() => onClick()} className={`   w-fit text  whitespace-nowrap border-primary  rounded-[7px] bg-white`}>
        <>
          <span className="w-full"> {CTA}</span>
        </>
      </div>
    </div>
  );
};

export default TagComponent;
