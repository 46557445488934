import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { useNavigate, useParams } from "react-router-dom";
import backArrow from "../../assets/icons/up arrow.svg";
import { distributorDetails } from "../../redux/features/distributoreCatalogue/distributoreCatalogue";
import { nanoid } from "nanoid";
import ReactImageGallery from "react-image-gallery";
import JewelLogo from "../../assets/Logo/logo 2.png";
import { addToCart, getAllCart } from "../../redux/features/distributoreCart/distributoreCart";
import ButtonComponent from "../../components/ButtonComponent";
import { useCookies } from "react-cookie";

type Props = {};

const DistributorCatalogueDetails = (props: Props) => {
  const [cookies, setCookie] = useCookies(["is_readOnly"]);
  const [state, setState] = useState(false);

  const Details = useAppSelector((state) => state.distributoreCatalogue.designDetails);
  const dispatch = useAppDispatch();
  const { vid } = useParams();
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (vid) {
      dispatch(distributorDetails(vid));
    }
  }, [vid]);
  useEffect(() => {
    if (Array.isArray(Details?.CartDesignInfo)) {
      if (Details?.CartDesignInfo[0]?.quantity) {
        setState(true);
      }
    }
  }, [Details]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  function handelAddtoCart() {
    dispatch(
      addToCart({
        distributor_catalogue_id: id,
        add_cart: [
          {
            cata_design_id: vid,
          },
        ],
      })
    ).then((res) => {
      if (res.payload.success) {
        setState(true);
      } else {
        setState(false);
      }
      dispatch(getAllCart());
    });
  }

  function renderLeftNav(onClick: any, disabled: any) {
    return <button type="button" className="image-gallery-left-nav" aria-label="Prev Slide" disabled={disabled} onClick={onClick}></button>;
  }

  function renderRightNav(onClick: any, disabled: any) {
    return <button type="button" className="image-gallery-right-nav" aria-label="Next Slide" disabled={disabled} onClick={onClick}></button>;
  }
  return (
    <div className="min-h-screen w-[90%] sm:w-[66%] md:w-[75%] lg:w-[60%] m-auto text-primary">
      <div className="flex flex-col gap-9">
        <div className="head">
          <div className="flex pt-4 items-center" onClick={() => navigate(`/distributor/${id}`)}>
            <img src={backArrow} alt="" className="-rotate-90 h-3 w-3" /> Back
          </div>
        </div>
        <div className="flex flex-col gap-9">
          <div className="border rounded-2xl p-9 flex justify-between">
            <div className="text-primary">
              <span className="font-bold ">Category:</span> <>{Details?.designs?.Category?.category_name}</>
            </div>
            <div className="text-primary">
              <span className="font-bold ">Code:</span> {Details?.variant_number}
            </div>
          </div>
          <div className="grid grid-cols-12 gap-5 ">
            <div className="col-span-12 lg:col-span-6 ">
              <div className="min-h-[300px] sm:min-h-[400px] flex border rounded-[10px] ">
                {Details?.designs && (
                  <ReactImageGallery
                    //renderRightNav={renderRightNav}
                    //renderLeftNav={renderLeftNav}
                    items={Details?.designs?.image_files?.map((i: any) => {
                      return { original: i.ImageURL, thumbnail: i.ImageURL };
                    })}
                    onErrorImageURL={JewelLogo}
                  />
                )}
              </div>

              <div className="py-2">
                {cookies.is_readOnly === "0" ? (
                  !Details?.is_sold ? (
                    <ButtonComponent onClick={!state ? handelAddtoCart : () => {}} buttonType="button" CTA={!state ? "Add to Cart" : "In Cart"} />
                  ) : null
                ) : null}
              </div>
            </div>
            <div className="col-span-12 lg:col-span-6 ">
              <h6 className="md:text-2xl text-xl font-bold">Product Details</h6>
              <div className="py-5">
                <ul className="w-full flex flex-col gap-2">
                  <li className="list-none w-full flex justify-between">
                    <span className="font-bold  text-sm md:text-lg">Gross Weight</span>
                    <span className="md:text-lg text-sm">{Details?.gross_weight}</span>
                  </li>
                  <li className="list-none w-full flex justify-between">
                    <span className="font-bold text-sm md:text-lg">Net Weight</span>
                    <span className="md:text-lg text-sm">{Details?.net_weight}</span>
                  </li>
                  <li className="list-none w-full flex justify-between">
                    <span className="font-bold text-sm md:text-lg">Karat</span>
                    <span className="md:text-lg text-sm">{Details?.karat}</span>
                  </li>
                  <li className="list-none w-full flex justify-between">
                    <span className="font-bold text-sm md:text-lg">Collection</span>
                    <span className="md:text-lg text-sm">{Details?.designs?.DesignCollectionNew[0]?.collectionInfo.name}</span>
                  </li>
                  <li className="list-none w-full flex justify-between">
                    <span className="font-bold text-sm md:text-lg">Gold Color</span>
                    <span className="md:text-lg text-sm">{Details?.designs?.variant_attribute}</span>
                  </li>
                </ul>
              </div>
              <hr />
              <div className="py-5 col-span-6">
                <h6 className="md:text-2xl text-xl font-bold">Material Details</h6>
                <div className="">
                  <ul className="grid grid-cols-6">
                    <ul className="col-span-6 flex justify-between">
                      <li className="col-span-1">
                        <div className="font-bold">Type</div>

                        {Details?.designs?.design_molds.map((i: { design_mold_stocks: any[] }) =>
                          i.design_mold_stocks.map((j) => <div key={nanoid()}>{j?.stock_name?.stock_name_code?.stock_group?.group_name}</div>)
                        )}

                        {/* {Details?.designs?.design_molds[0]?.design_mold_stocks?.map((i: any) => (
                          <div key={nanoid()}>{i?.stock_name?.stock_name_code?.stock_group?.group_name}</div>
                        ))} */}
                      </li>
                      <li className="col-span-1">
                        <div className="font-bold">Material</div>
                        {Details?.designs?.design_molds.map((i: { design_mold_stocks: any[] }) =>
                          i.design_mold_stocks.map((j) => <div key={nanoid()}>{j?.stock_name?.stock_name_code?.stock_name}</div>)
                        )}

                        {/* {Details?.designs?.design_molds[0]?.design_mold_stocks?.map((i: any) => (
                          <div key={nanoid()}>{i?.stock_name?.stock_name_code?.stock_name}</div>
                        ))} */}
                      </li>
                      <li className="col-span-1">
                        <div className="font-bold">Wt</div>
                        {/* {Details?.designs?.design_molds[0]?.design_mold_stocks?.map((i: any) => (
                          <div key={nanoid()}>{parseFloat(i?.actual_weight).toFixed(3)}</div>
                        ))} */}

                        {Details?.designs?.design_molds.map((i: { design_mold_stocks: any[] }) =>
                          i.design_mold_stocks.map((j) => <div key={nanoid()}>{parseFloat(j?.actual_weight).toFixed(3)}</div>)
                        )}
                      </li>
                    </ul>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DistributorCatalogueDetails;
