import React, { useEffect } from "react";
import ProfileDropDown from "../container/ProfileDropDown";
import SearchBoxComponent from "./SearchBoxComponent";
import { useAppDispatch, useAppSelector } from "../redux/app/hooks";
import { distributorDesignList, distributoreProfile, setSearchFilter } from "../redux/features/distributoreCatalogue/distributoreCatalogue";
import { useCookies } from "react-cookie";
import { useMatch, useParams } from "react-router-dom";
import { designListForLead, searchFilters } from "../redux/features/leadUserCatalogue/leadUserCatalogue";
import { leadProfile } from "../redux/features/userAuth/userAuth";

type Props = {};

const NavbarComponent = (props: Props) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [cookies] = useCookies(["type"]);
  // const [searChnage, setSearChnage] = useState("");
  const disCategoryIds = useAppSelector((state) => state.distributoreCatalogue.categoryIds);
  const diskarat = useAppSelector((state) => state.distributoreCatalogue.karat);
  const cataCategoryIds = useAppSelector((state) => state.leadUserCatalogue.categoryid);
  const catakarat = useAppSelector((state) => state.leadUserCatalogue.karat);
  const isdistPostRoute: any = useMatch("/distributor/:id");
  const iscataPostRoute: any = useMatch("/catalogues/:id");

  const handleFilterDistributor = (e: any) => {
    dispatch(setSearchFilter(e));
  };
  const handleFilterMyCataluge = (e: any) => {
    dispatch(searchFilters(e));
  };

  function isBlank(string: string) {
    return /^\s*$/.test(string);
  }

  useEffect(() => {
    if (cookies.type === "1") {
      dispatch(distributoreProfile(id));
    }
    if (cookies.type === "0") {
      dispatch(leadProfile(id));
    }
  }, []);

  const cataloguesearchFilter = useAppSelector((state) => state.leadUserCatalogue.searchFilter);
  const searchFilter = useAppSelector((state) => state.distributoreCatalogue.searchFilter);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (cookies.type === "1") {
        if (disCategoryIds.length !== 0 && !isBlank(searchFilter)) {
          dispatch(
            distributorDesignList({
              payload: {
                page: 1,
                karat: diskarat,
                categoryList: disCategoryIds,
                distributor_catalogue_id: id,
                variant_number: searchFilter,
              },
              isInfiniteScroll: false,
            })
          );
        }
      } else {
        if (cataCategoryIds.length !== 0 && !isBlank(cataloguesearchFilter)) {
          dispatch(
            designListForLead({
              payload: {
                page: 1,
                karat: catakarat,
                catalogue_id: id,
                category_id: cataCategoryIds,
                variant_number: cataloguesearchFilter,
                search_from_category: 1,
              },
              isInfiniteScroll: false,
            })
          );
        }
      }
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchFilter, cataloguesearchFilter]);

  const distributorLogo = useAppSelector((state) => state.distributoreCatalogue.company.ImageURL);

  return (
    <div className="shadow-menu justify-between items-center">
      <div className="flex container justify-between items-center h-[110px]">
        <div className="flex flex-row  justify-evenly">
          <div
            className={
              cookies.type === "1"
                ? "w-[6.25rem] sm:w-[280px] grid gap-4 grid-cols-2 sm:grid-cols-2 h-[110px] mx"
                : "w-[6.25rem] sm:w-[280px] grid gap-4 grid-cols-1 sm:grid-cols-2 h-[110px] mx"
            }
          >
            <img className="m-auto grid-cols-1 w-32" src="/logo 2.png " alt="" />
            {cookies.type === "1" && <img className="m-auto grid-cols-1 w-24  " src={distributorLogo} alt="" />}
          </div>
        </div>

        <div className="h-[20px] flex gap-5  flex-row justify-between items-center">
          {isdistPostRoute?.pattern?.path === "/distributor/:id" || iscataPostRoute?.pattern?.path === "/catalogues/:id" ? (
            <SearchBoxComponent
              searChnage={cookies.type === "1" ? searchFilter : cataloguesearchFilter}
              setSearChnage={cookies.type === "1" ? handleFilterDistributor : handleFilterMyCataluge}
            />
          ) : (
            ""
          )}
          <ProfileDropDown />
        </div>
      </div>
    </div>
  );
};

export default NavbarComponent;
