import React, { useEffect, useState } from "react";
import ButtonComponent from "./ButtonComponent";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import jewelLogo from "../assets/Logo/logo 2.png";
import { useAppDispatch } from "../redux/app/hooks";
import { addToCart, getAllCart } from "../redux/features/distributoreCart/distributoreCart";
import { useCookies } from "react-cookie";

type Props = {
  img: string;
  varient: string;
  link: string;
  net_weight: string;
  available_stock: number;
  categoryId: number;
  Karat: number;
  cartDetails: any;
  isSoldOut: number;
};

const DistributorCardComponent = ({ img, varient, link, net_weight, available_stock, categoryId, Karat, cartDetails, isSoldOut }: Props) => {
  const [cookies, setCookie] = useCookies(["is_readOnly"]);
  const [state, setState] = useState(true);
  const [imageSrc, setImageSrc] = React.useState(img);
  const params = useParams();
  const handleImageError = () => {
    setImageSrc(jewelLogo);
  };
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Get the value of a specific query parameter

  useEffect(() => {
    if (cartDetails[0]?.quantity) {
      setState(false);
    }
  }, [cartDetails]);

  function handelAddtoCart(id: any) {
    dispatch(
      addToCart({
        distributor_catalogue_id: params.id,
        add_cart: [
          {
            cata_design_id: id,
          },
        ],
      })
    ).then((res) => {
      if (res.payload.success) {
        setState(false);
      } else {
        setState(true);
      }
      dispatch(getAllCart());
    });
  }
  return (
    <div className={`shadow-menu rounded-[10px] ${isSoldOut && available_stock <= 1 ? "bg-slate-200" : "bg-white"} flex min-h-fit w-full flex-col px-8 py-2`}>
      <div>
        <div className="relative" onClick={ available_stock > 1 ? ()=> navigate(`/distributor/${params.id}/${varient}`) : available_stock === 1 ? () => navigate(link) : () => {}}>
          <p></p>
          {available_stock !== 1 ? (
            <span className={`w-10 h-10  top-0 -right-5 flex-center ${isSoldOut ? "bg-utils-sold-out" : "bg-secondary"} rounded-[100%] text-primary absolute`}>
              {available_stock}
            </span>
          ) : null}
          <div className="py-4 ">
            <img className="w-full h-[250px]  object-contain " onError={handleImageError} src={imageSrc} alt={""} />{" "}
          </div>
          <p className="text-base">{varient}</p>
          <div className="flex flex-row gap-3 justify-between items-end">
            <h4 className="text-lg` font-bold">{net_weight} gms</h4>
            <h4 className="text-lg ">{Karat}K</h4>
          </div>
        </div>
        {/* </Link> */}
        {cookies.is_readOnly === "0" ? (
          <>
            {available_stock > 1 ? (
              <div className="py-2">
                <ButtonComponent onClick={() => navigate(`/distributor/${params.id}/${varient}`)} CTA="View Design" variant="secondary" />
              </div>
            ) : (
              <>
                {isSoldOut ? (
                  <div className="py-2">
                    <ButtonComponent onClick={() => {}} CTA={"Sold Out"} variant="sold-out" />
                  </div>
                ) : (
                  available_stock === 1 && (
                    <div className="py-2">
                      <ButtonComponent onClick={() => handelAddtoCart(categoryId)} CTA={state !== false ? `Add To Cart` : `In Cart`} variant="secondary" />
                    </div>
                  )
                )}
              </>
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default DistributorCardComponent;
