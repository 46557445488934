import React, { useEffect, useRef, useState } from "react";
import CardComponent from "../../components/CardComponent";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { getCategoryList, designListForLead } from "../../redux/features/leadUserCatalogue/leadUserCatalogue";
import { useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { getAllCart } from "../../redux/features/leadUserCart/leadUserCart";
import ModalComponent from "../../components/ModalComponent";
import FilterForm from "../../container/FilterForm";
import { CategoryFilter } from "../../container/CategoryFilter";
import { KaratFilter } from "../../container/KaratFilter";
import TagComponent from "../../components/TagComponent";
import JewelLoader from "../../assets/Logo/logo 2.png";
import { nanoid } from "nanoid";
import CartButton from "../../components/CartButton";
type Props = {};

const UserCatalogue = React.memo((props: Props) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const ModalRef = useRef<HTMLDivElement | null>(null);
  const [filterView, setfilterView] = useState<boolean>(false);
  const detailsList = useAppSelector((state) => state.leadUserCatalogue.designList);
  const designListPage = useAppSelector((state) => state.leadUserCatalogue.designListPage);
  const categoryid = useAppSelector((state) => state.leadUserCatalogue.categoryid);
  const karat = useAppSelector((state) => state.leadUserCatalogue.karat);
  const categoryList = useAppSelector((state) => state.leadUserCatalogue.categoryList);
  const categoryName = useAppSelector((state) => state.leadUserCatalogue.catalogueName);
  const counts = useAppSelector((state) => state.leadUserCatalogue.count);
  const hasMore = useAppSelector((state) => state.leadUserCatalogue.hasMore);
  const addedToCart = useAppSelector((state) => state.leadUserCart.add_cart);
  const cataloguesearchFilter = useAppSelector((state) => state.leadUserCatalogue.searchFilter);

  useEffect(() => {
    if (categoryid?.length) {
      if (cataloguesearchFilter === "") {
        dispatch(
          designListForLead({
            payload: {
              page: 1,
              karat: karat,
              catalogue_id: `${id}`,
              category_id: categoryid,
              search_from_category: 1,
              variant_number: cataloguesearchFilter,
            },
            isInfiniteScroll: false,
          })
        )

      }
    }
  }, [categoryid, karat, cataloguesearchFilter]);

  useEffect(() => {
    document.title = `Jewel | ${categoryName}`;
  }, [categoryName]);

  useEffect(() => {
    dispatch(getCategoryList(id));
  }, []);

  useEffect(() => {
    dispatch(getAllCart());
  }, [addedToCart]);

  const fetchMore = () => {
    dispatch(
      designListForLead({
        payload: {
          page: designListPage + 1,
          karat: karat,
          catalogue_id: `${id}`,
          category_id: categoryid,
          search_from_category: 1,
        },
        isInfiniteScroll: true,
      })
    );
  };

  return (
    <>
      {filterView ? (
        <ModalComponent setShow={setfilterView} show={filterView}>
          <FilterForm ref={ModalRef} closeForm={setfilterView} />
        </ModalComponent>
      ) : null}
      <div className="container">
        <div className="min-h-[300px] sm:min-h-screen">
          <div className="flex my-[30px] justify-between items-center">
            <div className="title">
              <h4 className="text-xl md:text-2xl text-primary font-bold">{categoryName}</h4>
              <h6 className="text-primary">Total {counts} items </h6>
            </div>
            <CartButton />
          </div>

          <div
            className="fixed right-5 bottom-10 bg-primary text-secondary p-2 rounded-full hidden sm:block"
            onClick={(e) => {
              window.scroll(0, 0);
            }}
          >
            Top
          </div>
          <div className="flex sm:hidden gap-3 flex-row overflow-auto hide-scroll w-32 ">
            <TagComponent
              icon="/icons8-filter-24.png"
              CTA="Filter"
              onClick={() => {
                setfilterView(true);
              }}
            />
            {/* <TagComponent
              CTA="All"
              onClick={() => {
                setfilterView(true);
              }}
            />
            {categoryList.map((i) => (
              <TagComponent
                CTA={i.category_name}
                onClick={() => {
                  setfilterView(true);
                }}
                key={nanoid()}
              />
            ))} */}
          </div>
          <div className=" grid grid-cols-12 gap-2">
            <div className="hidden lg:block col-span-2">
              <div className="shadow-menu  rounded-[10px] ">
                <div className="p-6">
                  <h3 className="text-base font-bold">Categories</h3>
                  <CategoryFilter />
                </div>
              </div>
              <div className="shadow-menu rounded-[10px]">
                <div className="p-6 ">
                  <h3 className="text-base font-bold">Karat</h3>
                  <div className="flex flex-row gap-[10px]">
                    <KaratFilter />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-10 ">
              <InfiniteScroll
                className="col-span-12 lg:col-span-10 grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-7"
                dataLength={detailsList?.length}
                hasMore={hasMore}
                loader={
                  <>
                    <img className="" src={JewelLoader} alt="loader" />
                  </>
                }
                next={() => {
                  fetchMore();
                }}
              >
                {detailsList.map((i) => (
                  <div key={nanoid()} className="col-span-1">
                    <CardComponent
                      cartData={i.CartData}
                      // onClick={handleCart}
                      category_id={i.Category.id}
                      design_id={i.id}
                      karat={i.design_weights[0].karat}
                      link={`/catalogues/${id}/${i.id}`}
                      img={i.ImageURL}
                      varient={i.variant_number}
                      weight={i.design_weights[0].net_weight}
                    />
                  </div>
                ))}
              </InfiniteScroll>
              {detailsList.length === 0 && <p className="text-center font-bold">No Design available</p>}
            </div>
          </div>
          <div className="footer"></div>
        </div>
      </div>
    </>
  );
});

export default UserCatalogue;
