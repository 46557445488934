import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ButtonComponent from "../../components/ButtonComponent";
import MobileCodeDropDownComponent from "../../components/MobileCodeDropDownComponent";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { getCountries, setCountryCode, setMobileNumber, setOTPstatus } from "../../redux/features/userAuth/userAuth";
import { verifyMobile, distributoreLoginApi } from "../../redux/features/distributoreCatalogue/distributoreCatalogue";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import OTPInput from "react-otp-input";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toastComponent } from "../../components/ToastComponent";
import { useCookies } from "react-cookie";

type Props = {};

const DistributorLoginPage = (props: Props) => {
  const [cookie, setCookie] = useCookies(["authToken", "refreshToken", "type", "is_readOnly"]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const OTPsent = useAppSelector((state) => state.distributoreCatalogue.OTPSent);
  const [OTPvalue, setOTPvalue] = useState("");
  const schema = yup.object().shape({
    mobile: yup.string().matches(/^\+?\d{10,14}$/, "Enter a Valid Mobile Number"),
  });
  const countries = useAppSelector((state) => state.userAuth?.countries);

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const setFormValue = (e: any) => {
    setValue("country", e.id);
    setValue("country_code", e.phonecode);
    dispatch(setCountryCode({ countryCode: e.phonecode, id: e.id }));
  };

  const mobileNumber = watch("mobile");
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const country_code = useAppSelector((state) => state.distributoreCatalogue.countryCode);
  const sentOTP = () => {
    dispatch(setMobileNumber(mobileNumber));
    dispatch(verifyMobile({ mobile_number: mobileNumber, distributor_catalogue_id: id, country_code: country_code })).then((res) => {
      res.payload.success === true ? toastComponent(res.payload.message, "success") : toastComponent(res.payload.message, "error");
    });
  };
  const handleReset = () => {
    reset();
    setOTPvalue("");
    dispatch(setOTPstatus(false));
  };
 

  useEffect(() => {
    if (mobileNumber === "") {
      dispatch(setOTPstatus(false));
    }
  }, [mobileNumber]);

  const otpHandle = () => {
    dispatch(distributoreLoginApi({ mobile_number: mobileNumber, otp: OTPvalue, distributor_catalogue_id: id, country_code: country_code })).then((res) => {
      if (res.payload.success === true) {
        if (res.payload?.data?.is_register) {
          localStorage.setItem("name", res?.payload?.data?.full_name);
          localStorage.setItem("number", res?.payload?.data?.mobile_number);
          setCookie("authToken", res.payload.data.authToken, { path: "/" });
          setCookie("refreshToken", res.payload.data.refreshToken, { path: "/" });
          setCookie("type", 1, { path: "/" });
          setCookie("is_readOnly", res.payload.data.is_readOnly ? 1 : 0, { path: "/" });
          toastComponent(res.payload.message, "success");
          dispatch(setOTPstatus(false));
          navigate(`/distributor/${id}`);
        } else {
          dispatch(setOTPstatus(false));
          navigate(`distributor-register?id=${id}`);
        }
      } else {
        toastComponent(res.payload.message, "error");
      }
    });
  };

  useEffect(() => {
    dispatch(getCountries());
  }, []);
  useEffect(() => {
    Object.values(errors).forEach((element: any) => {
      toastComponent(element.message, "error");
    });
  }, [errors]);

  useEffect(() => {
    document.title = "JEWEl | Login to Distributor Catalogue";
  }, []);

  return (
    <div>
      <div className="flex justify-center items-center flex-col ">
        <img className="mt-[50px] sm:mt-[100px] mb-[50px] w-[200px]" src="/logo 2.png" alt="" />
        <h3 className="text-primary pb-[30px] text-center text-[20px] sm:text-[22px] font-bold ">Sign-in to view Distributor Catalogue</h3>
        <form onSubmit={!OTPsent ? handleSubmit(sentOTP) : handleSubmit(otpHandle)} className="flex-center gap-[30px]  w-[90%] sm:w-[96%] md:w-[45%]  lg:w-[25%] flex-col">
          {OTPsent ? (
            <button type="button" className="w-full text-left" onClick={handleReset}>
              Back
            </button>
          ) : null}
          <MobileCodeDropDownComponent
            isDisabled={OTPsent}
            register={register}
            inputRef={"mobile"}
            countries={countries}
            asterisk
            value={mobileNumber}
            placeHolder="Enter mobile number"
            label="Mobile Number"
            setFormValue={setFormValue}
          />
          {OTPsent ? (
            <div className="flex w-full flex-col">
              <label className="text-base font-bold text-primary" htmlFor="">
                Enter OTP
                <span className="text-red-500"> *</span>
              </label>
              <OTPInput
                containerStyle={"w-flex w-full justify-center"}
                inputStyle={"border-b-2 border-b-primary-light w-full flex px-4 mr-3 text-base outline-none"}
                value={OTPvalue}
                onChange={setOTPvalue}
                numInputs={6}
                renderSeparator={<span></span>}
                inputType="tel"
                renderInput={(props) => <input {...props} />}
              />
            </div>
          ) : null}
          <ButtonComponent buttonType={"submit"} CTA={!OTPsent ? "Get OTP" : "Log in"} variant={"secondary"} />
        </form>
      </div>
    </div>
  );
};

export default DistributorLoginPage;
