export const FooterRoutes = [
  {
    officeType: "Corporate Office",
    city: "Ahmedabad",
    contactInfo: [
      {
        type: "address",
        contact:
          "Ahmedabad, Gujarat , India.",
        icon: "/location.svg",
      },
      {
        type: "email",
        email: "connect@jewelmaker.co",
        icon: "/email.svg",
      },
      {
        type: "phone",
        tel: "+91 00000-00000",
        icon: "/call.svg",
      },
    ],
  },
  {
    officeType: "Branch Office",
    city: "Ahmedabad",
    contactInfo: [
      {
        type: "address",
        contact:
          "Ahmedabad, Gujarat , India.",
        icon: "/location.svg",
      },
      {
        type: "email",
        email: "connect@jewelmaker.co",
        icon: "/email.svg",
      },
      {
        type: "phone",
        tel: "+91 00000-00000",
        icon: "/call.svg",
      },
    ],
  },
];
