import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import InputComponent from "../../components/InputComponent";
import MobileCodeDropDownComponent from "../../components/MobileCodeDropDownComponent";
import SelectComponent from "../../components/SelectComponent";
import { getCities, getCountries, getStates, setCityCode, setStateCode } from "../../redux/features/userAuth/userAuth";
import ButtonComponent from "../../components/ButtonComponent";
import { toastComponent } from "../../components/ToastComponent";
import { distributoreSignUpApi } from "../../redux/features/distributoreCatalogue/distributoreCatalogue";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";

type Props = {};

const RegisterDistributorPage = (props: Props) => {
  const dispatch = useAppDispatch();

  const [_cookie, setCookie] = useCookies(["authToken", "refreshToken", "type", "is_readOnly"]);
  const countryId = useAppSelector((state) => state.userAuth.country_id);
  const countries = useAppSelector((state) => state.userAuth.countries);
  const mobileNumber = useAppSelector((state) => state.userAuth.mobileNumber);
  const countryCode = useAppSelector((state) => state.userAuth.countryCode);
  const states = useAppSelector((state) => state.userAuth.states);
  const cities = useAppSelector((state) => state.userAuth.cities);
  const stateID = useAppSelector((state) => state.userAuth.stateId);

  const RegXP = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  const pincodeRegex = /^\d{6}$/;

  const schema = yup.object().shape({
    distributor_catalogue_id: yup.string(),
    full_name: yup.string().min(2).required(),
    country_code: yup.string().required(),
    mobile_number: yup.string().matches(/^\+?\d{10,14}$/, "Enter a Valid Mobile Number"),
    email: yup.string().email().required(),
    company_name: yup.string().min(2).required(),
    designation: yup.string().required(),
    country: yup.string().required(),
    state: yup.string().required(),
    city: yup.string().required(),
    pincode: yup.string().matches(pincodeRegex, "Pincode must be in 6 numbers.").required().min(6).max(6),
  });

  useEffect(() => {
    if (countries.length <= 1) {
      dispatch(getCountries());
    }
  }, []);

  useEffect(() => {
    dispatch(getStates(Number(countryId)));
  }, [countryId]);

  useEffect(() => {
    dispatch(getCities(Number(stateID)));
  }, [stateID]);

  const {
    control,
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), defaultValues: { country_code: countryCode, country: countryId, mobile_number: mobileNumber } });

  const values = watch();

  useEffect(() => {
    Object.values(errors)
      .slice(0, 1)
      .map((element: any) => {
        toastComponent(element.message, "error");
      });
  }, [errors]);

  const handleForm = (data: any) => {
    dispatch(distributoreSignUpApi({ distributor_catalogue_id: id, ...data })).then((res) => {
      if (res.payload.success) {
        localStorage.setItem("name", res?.payload?.data?.full_name);
        localStorage.setItem("number", res?.payload?.data?.mobile_number);
        setCookie("authToken", res.payload.data.authToken, { path: "/" });
        setCookie("refreshToken", res.payload.data.refreshToken, { path: "/" });
        setCookie("type", 1, { path: "/" });
        setCookie("is_readOnly", res.payload.data.is_readOnly ? 1 : 0, { path: "/" });
        toastComponent(res.payload.message, "success");
        navigate(`/distributor/${id}`);
      }
    });
  };
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");

  useEffect(() => {
    if (mobileNumber === "" || mobileNumber === undefined) {
      navigate(`/?id=${id}`);
    }
  }, []);

  return (
    <div>
      <div>
        <div className="flex justify-center items-center flex-col ">
          <img className="mt-[100px] mb-[50px] w-[200px]" src="/logo 2.png" alt="" />
          <h3 className="text-primary text-[22px] font-bold ">Register to view Distributor Catalogue</h3>
          <form onSubmit={handleSubmit(handleForm)} className="flex-center gap-[30px] my-[30px] w-[90%] sm:w-[66%] md:w-[25%] flex-col">
            <InputComponent label="Full Name" placeHolder="Full Name" asterisk inputRef="full_name" register={register} />
            <InputComponent label="Email" placeHolder="Email" asterisk inputRef="email" register={register} />
            <InputComponent label="Company Name" placeHolder="Company Name" asterisk inputRef="company_name" register={register} />
            <InputComponent label="Designation" placeHolder="Designation" asterisk inputRef="designation" register={register} />
            <MobileCodeDropDownComponent
              register={register}
              asterisk
              inputRef="mobile_number"
              countries={countries}
              isDisabled={true}
              label="Mobile Number"
              placeHolder="Mobile Number"
              setFormValue={setValue}
              value=""
            />
            <SelectComponent control={control} inputRef="state" setValue={setStateCode} data={states} asterisk={true} placeHolder="Enter your State" label="States" />
            <SelectComponent control={control} inputRef="city" setValue={setCityCode} data={cities} asterisk={true} placeHolder="Enter your City" label="City" />
            <InputComponent label="Pincode" placeHolder="Pincode" asterisk inputRef="pincode" register={register} />
            <div className="flex w-full gap-7">
              <ButtonComponent
                CTA="Cancel"
                buttonType="button"
                onClick={() => {
                  navigate(`/?id=${id}`);
                }}
                variant="primary"
              />
              <ButtonComponent CTA="Submit" buttonType="submit" variant="secondary" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterDistributorPage;
