import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/app/hooks";
import { searchFilters, updatekarat } from "../redux/features/leadUserCatalogue/leadUserCatalogue";
import { nanoid } from "nanoid";
import ModalComponent from "../components/ModalComponent";
import ButtonComponent from "../components/ButtonComponent";
import { getAllCart, removeCartData } from "../redux/features/leadUserCart/leadUserCart";
import { toastComponent } from "../components/ToastComponent";

type Props = {
  postionFilter?: string;
};

export const KaratFilter = ({ postionFilter = "horizontal" }) => {
  const dispatch = useAppDispatch();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [karatFilter, setKaratFilter] = useState(22);
  const karat = useAppSelector((state) => state.leadUserCatalogue.karat);
  const cartDetails = useAppSelector((state) => state.leadUserCart.row);

  function changeKarat(id: number) {
    dispatch(updatekarat(id));
  }

  function handleRemoveall() {
    dispatch(searchFilters(""));
    changeKarat(karatFilter);
    setDeleteModal(false);
    const idArray: any = [];
    cartDetails.forEach((rowItem) => {
      rowItem.list.forEach((listItem) => {
        idArray.push(listItem.id);
      });
    });
    dispatch(removeCartData({ cart_id: idArray })).then((res) => {
      toastComponent(res.payload.message);
      dispatch(getAllCart());
    });
    setDeleteModal(false);
  }
  return (
    <>
      {deleteModal ? (
        <ModalComponent setShow={setDeleteModal} show={deleteModal}>
          <div className="flex flex-col h-full">
            <div className="text-primary font-bold px-6 py-4 border-b flex flex-row justify-between">
              Confirm karat change ?
              <h1 className="text-lg font-bold cursor-pointer" onClick={() => setDeleteModal(false)}>
                X
              </h1>
            </div>
            <div className="min-h-[3rem] px-6 py-2">On karat change cart will be Empty ?</div>
            <div className="flex flex-row justify-betwee px-6 gap-7 py-3">
              <ButtonComponent variant="primary" CTA="Cancel" onClick={() => setDeleteModal(false)} />
              <ButtonComponent variant="secondary" CTA="Submit" onClick={() => handleRemoveall()} />
            </div>
          </div>
        </ModalComponent>
      ) : null}
      <div className={`${postionFilter === "horizontal" ? "flex flex-row gap-4" : "flex flex-col gap-2"} py-4`}>
        {[14, 18, 20, 22].map((i) => (
          <div className=" flex justify-center cursor-pointer" key={nanoid()}>
            <span
              className={`border-secondary border py-1 px-1  rounded-md  ${karat === i ? "bg-secondary text-primary" : " text-secondary"}`}
              onClick={
                karat === i
                  ? () => {}
                  : () => {
                      setDeleteModal(true);
                      setKaratFilter(i);
                    }
              }
            >
              {i}
            </span>
          </div>
        ))}
      </div>
    </>
  );
};
