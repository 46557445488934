import { useEffect } from "react";
import ButtonComponent from "../../components/ButtonComponent";
import InputComponent from "../../components/InputComponent";
import MobileCodeDropDownComponent from "../../components/MobileCodeDropDownComponent";
import SelectComponent from "../../components/SelectComponent";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { getCountries, getStates, getCities, registerUser, setCityCode, setCountryCode, setStateCode } from "../../redux/features/userAuth/userAuth";
import { toastComponent } from "../../components/ToastComponent";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";

type Props = {};

const RegisterUserPage = (props: Props) => {
  const [_cookie, setCookie] = useCookies(["authToken", "refreshToken", "type"]);

  const countryId = useAppSelector((state) => state.userAuth.country_id);
  const countries = useAppSelector((state) => state.userAuth.countries);
  const mobileNumber = useAppSelector((state) => state.userAuth.mobileNumber);
  const states = useAppSelector((state) => state.userAuth.states);
  const cities = useAppSelector((state) => state.userAuth.cities);
  const stateID = useAppSelector((state) => state.userAuth.stateId);
  const OTPSent = useAppSelector((state) => state.userAuth.OTPSent);

  const dispatch = useAppDispatch();
  const RegXP = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  const schema = yup.object().shape({
    full_name: yup.string().min(2).required(),
    company_name: yup.string().min(2).required(),
    designation: yup.string().required(),
    email: yup.string().email().required(),
    country_code: yup.string().required(),
    country: yup.string().required(),
    state: yup.string().required(),
    city: yup.string().required(),
    mobile_number: yup.string().matches(/^\+?\d{10,14}$/, "Enter a Valid Mobile Number"),
  });

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), defaultValues: { country_code: "+91", country: "1", mobile_number: mobileNumber } });

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const id = searchParams.get("id");
  const Submit = (data: any) => {
    dispatch(registerUser({ ...data, catalog_id: id }))
      .then((res) => {
        localStorage.setItem("name", res?.payload?.data?.full_name);
        localStorage.setItem("number", res?.payload?.data?.mobile_number);
        setCookie("authToken", res.payload.authToken, { path: "/" });
        setCookie("refreshToken", res.payload.refreshToken, { path: "/" });
        setCookie("type", 0, { path: "/" });
        toastComponent(res.payload.message, "success");
        navigate(`/catalogues/${id}`);
      })
      .catch((err) => {});
  };

  const setFormValue = (e: any) => {
    setValue("country", e.id);
    setValue("country_code", e.phonecode);
    dispatch(setCountryCode({ countryCode: e.phonecode, id: e.id }));
  };

  useEffect(() => {
    Object.values(errors)
      .slice(0, 1)
      .map((element: any) => {
        toastComponent(element.message, "error");
      });
  }, [errors]);

  useEffect(() => {
    dispatch(getStates(Number(countryId)));
  }, [countryId]);

  useEffect(() => {
    dispatch(getCities(Number(stateID)));
  }, [stateID]);

  useEffect(() => {
    if (countries.length <= 1) {
      dispatch(getCountries());
    }
  }, []);

  useEffect(() => {
    if (mobileNumber == "") {
      navigate(`/login?id=${id}`);
    }
  }, []);

  useEffect(() => {
    document.title = "JEWEL | Register to Catalogues";
  }, []);

  return (
    <div>
      <div>
        <div className="flex justify-center items-center flex-col ">
          <img className="mt-[100px] mb-[50px] w-[200px]" src="/logo 2.png" alt="" />
          <h3 className="text-primary text-[22px] font-bold ">Register to view catalogue</h3>
          <form onSubmit={handleSubmit(Submit)} className="flex-center gap-[30px] my-[30px] w-[90%] sm:w-[66%] md:w-[25%] flex-col">
            <InputComponent inputRef="full_name" register={register} asterisk={true} placeHolder="Enter your full name" label="Full Name" />
            <InputComponent register={register} inputRef="company_name" asterisk={true} placeHolder="Enter your company name" label="Company Name" />
            <InputComponent register={register} inputRef="designation" asterisk={true} placeHolder="Enter Your Designation" label="Designation" />
            <MobileCodeDropDownComponent
              isDisabled={true}
              register={register}
              inputRef={"mobile_number"}
              countries={countries}
              asterisk
              value={mobileNumber}
              placeHolder="Enter mobile number"
              label="Mobile Number"
              setFormValue={setFormValue}
            />
            <InputComponent inputRef="email" register={register} asterisk={true} placeHolder="Enter your email id" label="Email id" />
            <SelectComponent inputRef="state" setValue={setStateCode} data={states} asterisk={true} placeHolder="Enter your State" label="States" control={control} />
            <SelectComponent control={control} inputRef="city" setValue={setCityCode} data={cities} asterisk={true} placeHolder="Enter your City" label="City" />
            <div className="flex w-full gap-[10px]">
              <ButtonComponent
                variant="primary"
                CTA="Cancel"
                onClick={() => {
                  navigate(`/login?id=${id}`);
                }}
              />
              <ButtonComponent variant="secondary" buttonType="submit" CTA="Continue" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterUserPage;
