import React, { useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { distributorVarientDetails } from "../../redux/features/distributoreCatalogue/distributoreCatalogue";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import VarientCardComponent from "../../components/VarientCardComponent";
import backArrow from "../../assets/icons/up arrow.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import JewelLoader from "../../assets/Logo/logo 2.png"
import { nanoid } from "nanoid";

type Props = {};

const DistributoreVeatrientDetails = (props: Props) => {
  const { id } = useParams();
  const { varient } = useParams();

  const dispatch = useAppDispatch();
  const varientRows = useAppSelector((state) => state.distributoreCatalogue.varient_rows);
  const varientHasmore = useAppSelector((state) => state.distributoreCatalogue.varientHasmore);
  const karat = useAppSelector((state) => state.distributoreCatalogue.karat);

  const page = useAppSelector((state) => state.distributoreCatalogue.page);
  // const [searchParams, setSearchParams] = useSearchParams();
  // Get the value of a specific query parameter

  const fetchMore = async () => {
    dispatch(
      distributorVarientDetails({
        payload: {
          page: page,
          variant_number: varient,
          distributor_catalogue_id: id,
          karat: karat,
        },
        isInfiniteScroll: true,
      })
    );
  };
  useEffect(() => {
    if (varient) {
      dispatch(
        distributorVarientDetails({
          payload: {
            page: 1,
            variant_number: varient,
            distributor_catalogue_id: id,
            karat: karat,
          },
          isInfiniteScroll: false,
        })
      );
    }
  }, [varient]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
      <Link to={`/distributor/${id}`} className="flex flex-row items-center pt-3">
        <img src={backArrow} alt="" className="-rotate-90 h-3 w-3" /> Back
      </Link>{" "}
      <h3 className="text-xl py-3 font-bold text-primary">Variant: {varient}</h3>
      <div className="col-span-12 sm:col-span-10 ">
        <InfiniteScroll
          className="col-span-12 lg:col-span-10 grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-7"
          dataLength={varientRows?.length}
          hasMore={varientHasmore}
          loader={
            <>
              <img className="" src={JewelLoader} alt="loader" />
            </>
          }
          next={() => {
            fetchMore();
          }}
        >
          {varientRows.map((i) => (
            <div key={nanoid()} className="col-span-1">
              <VarientCardComponent
                varientNumber={i.variant_number}
                isSold={i.is_sold}
                availableStock={i.available_stock}
                cartDetails={i.CartDesignInfo}
                categoryId={i.id}
                img={i.designs.image_files[0].ImageURL}
                varient={i.variant_number}
                net_weight={i.net_weight}
                karat={i.karat}
              />
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default DistributoreVeatrientDetails;
