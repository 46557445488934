import { useAppSelector } from "../../redux/app/hooks";
import jewelLogo from "../../../src/assets/Logo/logo 2.png";
import { nanoid } from "nanoid";
import ButtonComponent from "../../components/ButtonComponent";
import { useNavigate, useParams } from "react-router-dom";

type Props = {};

const DistributorePlaceOrder = (props: Props) => {
  const placeOrder = useAppSelector((state) => state.distributoreCart.placeOrderData);
  const placeOrderSoldData = useAppSelector((state) => state.distributoreCart.placeOrderSoldData);
  const ordernumber = useAppSelector((state) => state.distributoreCart.ordernumber);
  const user = useAppSelector((state) => state.distributoreCart.user);
  const distributore = useAppSelector((state) => state.distributoreCart.distributore);
  const date = useAppSelector((state) => state.distributoreCart.date);
  const { id } = useParams();
  const navigate = useNavigate();
  return (
    <div className="border border-secondary  m-5 md:m-10 h-full ">
      <div className="header">
        <div className="w-40 h-32 justify-center flex mx-auto">
          <img src="/order placed image.png" alt="empty cart" />
        </div>
        <div className="justify-center mx-auto flex ">
          <h1 className="my-2 font-bold pl-6 md:pl-0 text-lg md:text-2xl ">Congratulations, Your order has been placed!</h1>
        </div>
      </div>
      <div className="my-2">
        <div className="flex flex-row justify-between mx-5 md:mx-10 ">
          <div className="left">
            <div className="font-bold">
              Order NO: <span className="font-normal">{ordernumber}</span>
            </div>
            <div className="font-bold">
              Date: <span className="font-normal">{date}</span>
            </div>
          </div>
          <div className="right">
            <div className="font-bold">
              User: <span className="font-normal">{user.full_name}</span>
            </div>
            <div className="font-bold">
              Distibutor Name: <span className="font-normal">{distributore.client.name}</span>
            </div>
          </div>
        </div>
        <div className="border-b border-secondary block my-2 mx-5"></div>
        <div className="mx-5 md:mx10 ">
          <div className={`${placeOrderSoldData.length > 0 ? " block" : "hidden"} `}>
            {" "}
            <p className="font-bold text-lg">Sold Order Summary:</p>
            <div className="w-full my-4 overflow-x-auto hide-scroll">
              <table className="table-auto w-[150%] lg:w-full text-center">
                <thead className="md:contents hidden">
                  <tr className="bg-primary text-secondary text-sm	sm:text-base ">
                    <th>Image</th>
                    <th>Design No.</th>
                    <th>Karat</th>
                    <th>Gross WT</th>
                    <th>Net WT</th>
                    <th>Pieces</th>
                  </tr>
                </thead>
                <thead className="contents md:hidden">
                  <tr className="bg-primary text-secondary text-sm	sm:text-base">
                    <th className="w-[20%] md:w-0 p-5">Image</th>
                    <th className="w-[20%] md:w-0 p-5">Design No.</th>
                    <th className="w-[20%] md:w-0 p-5">Kt</th>
                    <th className="w-[20%] md:w-0 p-5">GWT</th>
                    <th className="w-[20%] md:w-0 p-5">NWT</th>
                    <th className="w-[20%] md:w-0 p-5">PCS</th>
                  </tr>
                </thead>
                <tbody className="text-xs sm:text-sm"></tbody>
                {placeOrderSoldData.map((i: any) => (
                  <tr key={nanoid()}>
                    <td>
                      <img
                        src={i.image_files.ImageURL}
                        alt="cata"
                        className="w-[100px]  mx-auto"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = jewelLogo;
                          currentTarget.className = "w-[60px]  mx-auto";
                        }}
                      />
                    </td>
                    <td>{i.variant_number}</td>
                    <td>{i.karat}</td>
                    <td>{i.gross_weight}</td>
                    <td>{i.net_weight}</td>
                    <td className="p-2"> {i.quantity}</td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
          <div>
            <p className="font-bold text-lg">Placed Order summary:</p>
            <div className="w-full my-4 overflow-x-auto hide-scroll">
              <table className="table-auto w-[150%] lg:w-full text-center">
                <thead className="md:contents hidden">
                  <tr className="bg-primary text-secondary text-sm	sm:text-base ">
                    <th>Image</th>
                    <th>Design No.</th>
                    <th>Karat</th>
                    <th>Gross WT</th>
                    <th>Net WT</th>
                    <th>Pieces</th>
                  </tr>
                </thead>
                <thead className="contents md:hidden">
                  <tr className="bg-primary text-secondary text-sm	sm:text-base">
                    <th className="w-[20%] md:w-0 p-5">Image</th>
                    <th className="w-[20%] md:w-0 p-5">Design No.</th>
                    <th className="w-[20%] md:w-0 p-5">Kt</th>
                    <th className="w-[20%] md:w-0 p-5">GWT</th>
                    <th className="w-[20%] md:w-0 p-5">NWT</th>
                    <th className="w-[20%] md:w-0 p-5">PCS</th>
                  </tr>
                </thead>
                <tbody className="text-xs sm:text-sm"></tbody>
                {placeOrder.map((i: any) => (
                  <tr key={nanoid()}>
                    <td>
                      <img
                        src={i.designs.image_files[0].ImageURL}
                        alt="cata"
                        className="w-[100px]  mx-auto"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = jewelLogo;
                          currentTarget.className = "w-[60px]  mx-auto";
                        }}
                      />
                    </td>
                    <td>{i.variant_number}</td>
                    <td>{i.karat}</td>
                    <td>{i.gross_weight}</td>
                    <td>{i.net_weight}</td>
                    <td className="p-2"> {i.quantity}</td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="my-3 w-44 flex justify-center mx-auto">
        <ButtonComponent CTA="Shop Now " onClick={() => navigate(`/distributor/${id}`)} />
      </div>
    </div>
  );
};

export default DistributorePlaceOrder;
