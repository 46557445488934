import React from "react";
import TabComponent from "../components/TabComponent";
import ButtonComponent from "../components/ButtonComponent";
import { KaratFilter } from "./KaratFilter";
import { DistributoreCategoryFilter } from "./DistributorCategoryFiltes";
import { DistributorKaratFilter } from "./DistributorKaratFilter";

type Props = {
  closeForm: Function;
  ref: any;
};

const DistibutorFilterForm = ({ closeForm, ref }: Props) => {
  return (
    <div className=" flex h-full  p-1 px-3 flex-col ">
      <div className="h-full">
        <div className="my-3 flex justify-between w-full">
          <span ref={ref} className="text-base text-primary font-bold">
            Filter
          </span>
          <span className="flex gap-2">
            <span className="text-[#9E9E9E] font-black px-4" onClick={() => closeForm(false)}>
              X
            </span>
          </span>
        </div>
      </div>
      <div className="border-t h-full">
        <div className="flex w-full  overflow-auto">
          <TabComponent ComponentList={[DistributoreCategoryFilter, DistributorKaratFilter]} TabIndex={["Category", "Karat"]} />
        </div>
      </div>
      <div className="my-2">
        <ButtonComponent CTA="Apply Filter" onClick={() => closeForm(false)} />
      </div>
    </div>
  );
};

export default DistibutorFilterForm;
