import { useEffect, useState } from "react";
import ButtonComponent from "../../components/ButtonComponent";
import { designDetailsForLead } from "../../redux/features/leadUserCatalogue/leadUserCatalogue";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { useNavigate, useParams } from "react-router-dom";
import ReactImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { nanoid } from "nanoid";
import backArrow from "../../assets/icons/up arrow.svg";
import { addToCart, getAllCart } from "../../redux/features/leadUserCart/leadUserCart";
import jewelLogo from "../../assets/Logo/logo 2.png";

type Props = {};

const UserCatalogueDetails = (props: Props) => {
  const { design } = useParams();
  const designMold = useAppSelector((state) => state.leadUserCatalogue.designDetails.design_molds);
  const Details = useAppSelector((state) => state.leadUserCatalogue.designDetails);
  const karat = useAppSelector((state) => state.leadUserCatalogue.karat);
  const dispatch = useAppDispatch();
  const [quanity, setquantity] = useState<string>("0");
  const [state, setState] = useState(true);
  const navigate = useNavigate();

  const RemoveFromtheCart = () => {
    if (Number(quanity) > 0) {
      setquantity((prev) => String(Number(prev) - 1));
      dispatch(
        addToCart({
          add_cart: [
            {
              design_id: Details.id,
              category_id: Details.category_id,
              karat: Details.design_weights[0].karat,
              quantity: -1,
            },
          ],
        })
      ).then((res) => {
        if (res.payload.success) {
          dispatch(getAllCart());
        }
      });
    } else {
      setState(true);
    }
  };

  const AddToCart = () => {
    setquantity((prev) => String(Number(prev) + 1));
    dispatch(
      addToCart({
        add_cart: [
          {
            design_id: Details.id,
            category_id: Details.category_id,
            karat: Details.design_weights[0].karat,
            quantity: 1,
          },
        ],
      })
    ).then((res) => {
      if (res.payload.success) {
        dispatch(getAllCart());
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (String(Details.CartData[0]?.quantity) !== "undefined") {
      setquantity(String(Details.CartData[0]?.quantity));
    } else {
      setquantity("0");
    }
  }, [Details]);

  useEffect(() => {
    document.title = `JEWEL | ${Details?.Category?.category_name}`;
  }, [Details?.Category?.category_name]);

  useEffect(() => {
    dispatch(designDetailsForLead({ design: design, karat: karat }));
  }, []);
  const { id } = useParams();

  function renderLeftNav(onClick: any, disabled: any) {
    return <button type="button" className="image-gallery-left-nav" aria-label="Prev Slide" disabled={disabled} onClick={onClick}></button>;
  }

  function renderRightNav(onClick: any, disabled: any) {
    return <button type="button" className="image-gallery-right-nav" aria-label="Next Slide" disabled={disabled} onClick={onClick}></button>;
  }

  return (
    <div className="min-h-screen w-[90%] sm:w-[66%] md:w-[75%] lg:w-[60%] m-auto text-primary">
      <div className="flex flex-col gap-9">
        <div className="head">
          <div className="flex pt-4 items-center cursor-pointer font-bold" onClick={() => navigate(`/catalogues/${id}`)}>
            <img src={backArrow} alt="" className="-rotate-90  h-3 w-3" /> Back
          </div>
        </div>
        <div className="flex flex-col gap-9">
          <div className="border rounded-2xl p-5 flex flex-col md:flex-row justify-between">
            <div className="text-primary">
              <span className="font-bold ">Category:</span> <>{Details?.Category?.category_name}</>
            </div>
            <div className="text-primary">
              <span className="font-bold ">Code:</span> <>{Details.variant_number}</>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-5 ">
            <div className="col-span-12 lg:col-span-6 ">
              <div className="min-h-[300px] sm:min-h-[400px] z-40 flex border rounded-[10px] ">
                <ReactImageGallery
                  //renderRightNav={renderRightNav}
                  //renderLeftNav={renderLeftNav}
                  items={Details.image_files.map((i) => {
                    return { original: i.image_file, thumbnail: i.image_file };
                  })}
                  onErrorImageURL={jewelLogo}
                />
              </div>
              <div className="grid grid-cols-6 gap-4">
                <div className="col-span-4 flex gap-4 cursor-pointer justify-between py-2">
                  <div onClick={AddToCart} className="bg-secondary w-1/4 cursor-pointer rounded-lg flex-center px-3">
                    <img className="m-auto" src="/plus.svg" alt="" />
                  </div>
                  <input
                    value={quanity}
                    disabled={true}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setquantity(e.target.value)}
                    className="min-h-[50px] rounded-[10px] w-1/2 text-center border"
                    type="number"
                  />
                  <div onClick={() => RemoveFromtheCart()} className="bg-primary-light w-1/4 cursor-pointer rounded-lg flex-center px-3">
                    <img src="/minus.svg" alt="" />
                  </div>
                </div>
                <div className="col-span-2 py-2">
                  <ButtonComponent variant="primary" CTA={"View Cart"} onClick={() => navigate(`/catalogues/${id}/order-cart`)} />
                </div>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-6 ">
              <h6 className="md:text-2xl text-xl font-bold">Product Details</h6>
              <div className="py-5">
                <ul className="w-full flex flex-col gap-2">
                  <li className="list-none w-full flex justify-between">
                    <span className="font-bold text-sm md:text-lg">Gross Weight</span>
                    <span className="md:text-lg text-sm">{Details.design_weights[0].gross_weight}</span>
                  </li>
                  <li className="list-none w-full flex justify-between">
                    <span className="font-bold text-sm md:text-lg">Net Weight</span>
                    <span className="md:text-lg text-sm">{Details.design_weights[0].net_weight}</span>
                  </li>
                  <li className="list-none w-full flex justify-between">
                    <span className="font-bold text-sm md:text-lg">Karat</span>
                    <span className="md:text-lg text-sm">{Details.design_weights[0].karat}</span>
                  </li>
                  <li className="list-none w-full flex justify-between">
                    <span className="font-bold text-sm md:text-lg">Stone Weight</span>
                    <span className="md:text-lg text-sm">{Details.design_weights[0].stone_weight}</span>
                  </li>

                  <li className="list-none w-full flex justify-between">
                    <span className="font-bold text-sm md:text-lg">Collection</span>
                    <span className="md:text-lg text-sm">{Details.collection[0]}</span>
                  </li>
                  <li className="list-none w-full flex justify-between">
                    <span className="font-bold text-sm md:text-lg">Gold Color</span>
                    <span className="md:text-lg text-sm">{Details.variant_attribute}</span>
                  </li>
                </ul>
              </div>
              <hr />
              <div className="py-5 col-span-6">
                <h6 className="md:text-2xl text-xl font-bold">Material Details</h6>
                <div className="">
                  <ul className="grid grid-cols-6">
                    <ul className="col-span-6 flex justify-between">
                      <li className="col-span-1">
                        <div className="font-bold">Type</div>
                        {designMold.map((i) => i.design_mold_stocks.map((j) => <div key={nanoid()}>{j?.stock_name?.stock_name_code?.stock_group?.group_name}</div>))}
                      </li>
                      <li className="col-span-1">
                        <div className="font-bold">Material</div>
                        {designMold.map((i) => i.design_mold_stocks.map((j) => <div key={nanoid()}>{j?.stock_name?.stock_name_code?.stock_name}</div>))}
                      </li>

                      <li className="col-span-1">
                        <div className="font-bold">Wt</div>
                        {designMold.map((i) => i.design_mold_stocks.map((j) => <div key={nanoid()}>{parseFloat(j?.actual_weight).toFixed(3)}</div>))}
                      </li>
                    </ul>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCatalogueDetails;
