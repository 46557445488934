import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { CookiesProvider } from "react-cookie";
// Public Routes
import LoginPage from "./page/PublicRoutes/LoginPage";
import DistributorLoginPage from "./page/PublicRoutes/DistributorLoginPage";
import RegisterUserPage from "./page/PublicRoutes/RegisterUserPage";
// AUTH
import LeadUserRoutes from "./auth/LeadUserRoutes";
import DistributorRoutes from "./auth/DistributorRotes";
// Distributor Flow
import DistributorCatalogue from "./page/DistributorUser/DistributorCatalogue";
import DistributorCatalogueDetails from "./page/DistributorUser/DistributorCatalogueDetails";
import DistributorOrder from "./page/DistributorUser/DistributorOrder";
// USER FLOW
import UserCatalogueDetails from "./page/LeadUser/UserCatalogueDetails";
import UserCatalogue from "./page/LeadUser/UserCatalogue";
import UserCatalogueOrder from "./page/LeadUser/UserCatalogueOrder";
import UserCatalogueForm from "./page/LeadUser/UserCatalogueForm";
// CSS
import "./styles/App.css";
import PublicRoutes from "./auth/PublicRoutes";
import DistributoreVeatrientDetails from "./page/DistributorUser/DistributoreVeatrientDetails";
import RegisterDistributorPage from "./page/PublicRoutes/RegisterDistributorPage";
import Error404 from "./page/PublicRoutes/Error404";
import PrivacyPolicy from "./page/PublicRoutes/PrivacyPolicy";
import OpenRoutes from "./auth/OpenRoutes";

function App() {
  return (
    <div>
      <CookiesProvider>
        <ToastContainer />
        <BrowserRouter>
          <Routes>
            {/* Public Routes */}
            <Route
              path="/"
              element={
                <PublicRoutes>
                  <DistributorLoginPage />
                </PublicRoutes>
              }
            />
            <Route path="/error" element={<Error404 />} />
            <Route
              path="/privacyPolicy"
              element={
                <OpenRoutes>
                  <PrivacyPolicy />
                </OpenRoutes>
              }
            />
            <Route
              path="/distributor-register"
              element={
                // <PublicRoutes>
                <RegisterDistributorPage />
                // </PublicRoutes>
              }
            />
            <Route
              path="/login"
              element={
                <PublicRoutes>
                  <LoginPage />
                </PublicRoutes>
              }
            />
            <Route
              path="/register"
              element={
                // <PublicRoutes>
                  <RegisterUserPage />
                // </PublicRoutes>
              }
            />

            {/* Distributor Routes Below */}
            <Route
              path="/distributor/:id"
              element={
                <DistributorRoutes>
                  <DistributorCatalogue />
                </DistributorRoutes>
              }
            />
            <Route
              path="/distributor/:id/:varient/:vid"
              element={
                <DistributorRoutes>
                  <DistributorCatalogueDetails />
                </DistributorRoutes>
              }
            />
            <Route
              path="/distributor/:id/:varient"
              element={
                <DistributorRoutes>
                  <DistributoreVeatrientDetails />
                </DistributorRoutes>
              }
            />
            <Route
              path="/distributor/:id/order-cart"
              element={
                <DistributorRoutes>
                  <DistributorOrder />
                </DistributorRoutes>
              }
            />

            {/* Lead User Routes */}
            <Route
              path="/catalogues/:id"
              element={
                <LeadUserRoutes>
                  <UserCatalogue />
                </LeadUserRoutes>
              }
            />
            <Route
              path="/catalogues/:id/:design"
              element={
                <LeadUserRoutes>
                  <UserCatalogueDetails />
                </LeadUserRoutes>
              }
            />
            <Route
              path="/catalogues/:id/order-cart"
              element={
                <LeadUserRoutes>
                  <UserCatalogueOrder />
                </LeadUserRoutes>
              }
            />
            <Route
              path="/catalogues/:id/order-cart/user-preference"
              element={
                <LeadUserRoutes>
                  <UserCatalogueForm />
                </LeadUserRoutes>
              }
            />
          </Routes>
        </BrowserRouter>
      </CookiesProvider>
    </div>
  );
}

export default App;
