import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/app/hooks";
import { setSearchFilter, updatekarat } from "../redux/features/distributoreCatalogue/distributoreCatalogue";
import { nanoid } from "nanoid";
import ModalComponent from "../components/ModalComponent";
import ButtonComponent from "../components/ButtonComponent";
import { getAllCart } from "../redux/features/leadUserCart/leadUserCart";
import { toastComponent } from "../components/ToastComponent";

type Props = {
  postionFilter?: string;
};

export const DistributorKaratFilter = ({ postionFilter = "horizontal" }) => {
  const dispatch = useAppDispatch();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [karatFilter, setKaratFilter] = useState(22);
  const karat = useAppSelector((state) => state.distributoreCatalogue.karat);
  const cartDetails = useAppSelector((state) => state.distributoreCatalogue.rows);

  const handleKarat = (i: any) => {
    dispatch(setSearchFilter(""));
    dispatch(updatekarat(i));
  };

  return (
    <div className={`${postionFilter === "horizontal" ? "flex flex-row gap-4" : "flex flex-col gap-2"} py-4`}>
      {[14, 18, 20, 22].map((i) => (
        <div className="pt-5 cursor-pointer" key={nanoid()}>
          <span
            className={`border-secondary border py-1 px-1  rounded-md  ${karat === i ? "bg-secondary text-primary" : " text-secondary"}`}
            onClick={
              karat === i
                ? () => {}
                : () => {
                    handleKarat(i);
                  }
            }
          >
            {i}
          </span>
        </div>
      ))}
    </div>
  );
};
