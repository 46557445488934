import React from "react";
import { useAppDispatch, useAppSelector } from "../redux/app/hooks";
import { addItem, removeItem } from "../redux/features/leadUserCatalogue/leadUserCatalogue";
import { toastComponent } from "../components/ToastComponent";
import { nanoid } from "nanoid";

type Props = {};

export const CategoryFilter = ({}: any) => {
  const categoryList = useAppSelector((state) => state.leadUserCatalogue.categoryList);
  const categoryid = useAppSelector((state) => state.leadUserCatalogue.categoryid);

  const dispatch = useAppDispatch();
  function handleClick(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.checked) {
      if (categoryid.length !== 1) {
        dispatch(removeItem({ id: e.target.value }));
      } else {
        toastComponent("Atleast 1 Category Need");
      }
    } else {
      dispatch(addItem({ id: e.target.value }));
    }
  }

  return (
    <ul className="cursor-pointer ">
      {categoryList?.map((i: any) => (
        <li className="my-1" key={nanoid()}>
          <label className="flex gap-1 items-baseline ">
            <input
              type="checkbox"
              name=""
              id=""
              onChange={(e) => handleClick(e)}
              value={i.id}
              checked={categoryid.findIndex((element: any) => element === Number(i.id)) === -1 ? false : true}
            />
            <span>{i.category_name}</span>
          </label>
        </li>
      ))}
    </ul>
  );
};
