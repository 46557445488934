import React, { useEffect, useState } from "react";
import ButtonComponent from "./ButtonComponent";
import { Link } from "react-router-dom";
import { addToCart, getAllCart } from "../redux/features/leadUserCart/leadUserCart";
import { useAppDispatch } from "../redux/app/hooks";
import jewelLogo from "../assets/Logo/logo 2.png";

type Props = {
  cartData: any[];
  img: string;
  varient: string;
  link: string;
  design_id: number;
  category_id: number;
  karat: number;
  weight: string;
  onClick?: () => void;
};

const CardComponent = ({ img, varient, link, design_id, category_id, karat, weight, onClick, cartData }: Props) => {
  const [quanity, setquantity] = useState<string>("0");
  const [state, setState] = useState(true);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (String(cartData[0]?.quantity) !== "undefined") {
      setquantity(String(cartData[0]?.quantity));
    } else {
      setquantity("0");
    }
  }, [cartData]);

  const RemoveFromtheCart = () => {
    if (Number(quanity) > 0) {
      setquantity((prev) => String(Number(prev) - 1));
      dispatch(
        addToCart({
          add_cart: [
            {
              design_id: design_id,
              category_id: category_id,
              karat: karat,
              quantity: -1,
            },
          ],
        })
      ).then((res) => {
        if (res.payload.success) {
          dispatch(getAllCart());
        }
      });
    } else {
      setState(true);
    }
  };

  const AddToCart = () => {
    setquantity((prev) => String(Number(prev) + 1));
    dispatch(
      addToCart({
        add_cart: [
          {
            design_id: design_id,
            category_id: category_id,
            karat: karat,
            quantity: 1,
          },
        ],
      })
    ).then((res) => {
      if (res.payload.success) {
        dispatch(getAllCart());
      }
    });
  };

  const [imageSrc, setImageSrc] = React.useState(img);

  const handleImageError = () => {
    setImageSrc(jewelLogo);
  };

  useEffect(() => {
    if (Number(quanity) > 0) {
      setState(false);
    }
  }, [quanity]);

  return (
    <div className="shadow-menu rounded-[10px] flex min-h-fit w-full flex-col px-8 py-2">
      <div>
        <Link to={link}>
          <p></p>
          <div className="py-4 ">
            <img className="w-full h-[250px]  object-contain" onError={handleImageError} src={imageSrc} alt={""} />
          </div>
          <p className="text-base">{varient}</p>
          {/* <h4 className="text-2xl font-bold">{weight} gm</h4> */}
          <div className="flex flex-row gap-3 justify-between items-end">
            <h4 className="text-lg font-bold">{weight} gms</h4>
            <h4 className="text-lg ">{karat}K</h4>
          </div>
        </Link>
        {state ? (
          <div className="py-2">
            <ButtonComponent onClick={() => {setState(false);AddToCart();}} CTA="Add To Cart" variant="secondary" />
          </div>
        ) : (
          <div className="flex gap-4 cursor-pointer justify-between py-2">
            <div onClick={AddToCart} className="bg-secondary p-3 w-1/4 rounded-lg">
              <img className="m-auto" src="/plus.svg" alt="" />
            </div>
            <input
              value={quanity}
              disabled={true}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setquantity(e.target.value)}
              className="min-h-[50px] rounded-[10px] w-1/2 text-center border"
              type="number"
            />
            <div onClick={() => RemoveFromtheCart()} className="bg-primary-light w-1/4 cursor-pointer rounded-lg flex-center px-3">
              <img src="/minus.svg" alt="" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardComponent;
